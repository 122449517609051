<template>
    <div class="mb-50">
        <div class="list-z">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
                <el-breadcrumb-item :to="{ path: '/' }"
                    >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item class="is-link"
                    v-if="previousRoute && !!previousRoute.value"
                    @click.native="toPreviousRoute"
                    >{{ previousRoute.label }}</el-breadcrumb-item
                >
                <el-breadcrumb-item>信用分详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="display: flex;">
            <detail-page style="flex: 1;"
                v-loading="loadingStatus"
                v-if="showDetail"
                :cards="cards"
                :title="title"
            />
            <div class="score-cell">
                <label class="score-label">实时信用分</label>
                <div class="score-value">{{score}}</div>
            </div>
        </div>
        <div class="more-detail" v-if="!!dataSourceUrl">
            <el-link v-if="!emptyInfo"
                :disabled="loadingStatus"
                class="title"
                :underline="false"
                @click="viewMoreDetail()"
                >查看更多详情
                <el-image
                    :src="require('../../assets/images/bjtsy/11941.png')"
                ></el-image>
            </el-link>
            <span v-else>未获取到信用分详情数据</span>
        </div>
    </div>
</template>

<script>
import DetailPage from "@/components/detail-page";
export default {
    props: {
        row: {
            type: Object,
            required: true,
            default: () => {},
        },
        previousRoute: {
            type: Object,
            default: () => {},
        }
    },
    components: {
        DetailPage,
    },
    data() {
        return {
            loadingStatus: false,
            dataSourceUrl: "",
            emptyInfo: false,
            showDetail: false,
            cards: [],
            title: "",
            creditCode: "",
            fromProvince: "",
            score: "",
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        toPreviousRoute() {
            this.showDetail = false;
            this.$emit('close');
        },
        viewMoreDetail() {
            // 跳转外链
            console.log("viewMoreDetail...creditCode=" + this.creditCode);
            window.open(this.dataSourceUrl, '_blank');
        },
        getDetail() {
            try {
                const item = this.row ? this.row : {};
                this.title = item.entName;
                this.creditCode = item.creditCode;
                this.fromProvince = item.fromProvince;
                this.score = item.score;
                this.dataSourceUrl = item.dataSourceUrl;
                this.cards = [
                    {
                        colsNum: 1,
                        title: item.entName,
                        fields: [
                            {
                                label: "统一社会信用代码",
                                value: item.creditCode,
                            },
                            {
                                label: "企业评分分类",
                                value: item.scoreName,
                            },
                            {
                                label: "企业评分日期",
                                value: item.date || '-',
                            },
                            {
                                label: "企业注册属地",
                                value: item.provinceCityCounty || '-',
                            },
                        ],
                    },
                ];
                this.showDetail = true; // 显示 DetailPage 组件
            } catch (error) {
                console.error(error);
                this.$message.error("获取信用分详情失败");
            } finally {
                this.loadingStatus = false;
            }
        }
    },
};
</script>
<style scoped>

::v-deep .is-link > .el-breadcrumb__inner {
    color: #303133 !important;
    font-weight: 700;
    text-decoration: none; 
    
}

::v-deep .is-link > .el-breadcrumb__inner:hover {
    color: #409EFF !important;
    cursor: pointer;
}

.list-z {
    text-align: left;
    font-weight: 500;
    color: #4e4e4e;
    line-height: 40px;
    margin-top: 35px;
    margin-bottom: 25px;
}
.fs-18 {
    font-size: 18px;
}
.mb-50 {
    margin-bottom: 50px;
}
.table-header-area {
    display: flex;
}
.table-header {
    width: 520px;
    height: 60px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    text-align: left;
    margin-top: 32px;
    margin-bottom: 32px;
    padding-left: 20px;
}

.table-title {
    width: 233px;
    height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN-Regular;
    font-weight: 500;
    line-height: 60px;
}

.right-header {
    text-align: right;
    margin-top: 32px;
    margin-bottom: 32px;
    padding-right: 20px;
    flex: 1;
}

::v-deep .el-tabs__nav.is-stretch > * {
    width: auto;
}
::v-deep .el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0px 0 30px;
}
::v-deep .detail-card {
    overflow: hidden;
    margin-bottom: 30px;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
    flex: 1;
    text-align: center;
    width: auto;
    height: 48px;
    font-size: 20px;
    line-height: 48px;
}
::v-deep .detail-card-label {
    display: table-cell;
    width: 232px;
    font-size: 20px;
    color: #888888;
    padding-top: 16px;
    padding-bottom: 16px;
}
::v-deep .detail-card-value {
    padding-bottom: 15px;
}
::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
    font-size: 18px;
}
::v-deep .detail-card-value {
    font-size: 20px;
}
::v-deep .detail-card-title {
    margin-bottom: 20px;
}
::v-deep .detail-card-divider {
    margin-bottom: 30px;
}

.more-detail {
    width: 100%;
    height: 50px;
    line-height: 50px;
    margin-top: 16px;
    background: #edf4ff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
}

.more-detail > .title {
    width: auto;
    height: 18px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular;
    font-weight: 400;
    color: #1890ff;
    line-height: 0px;
}

.score-cell {
    margin-top: 60px;
    width: 30%;
    border: 1px solid #EBEEF5;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.score-label {
    width: 100px;
    font-size: 20px;
    font-family: Source Han Sans CN-Regular;
    font-weight: bold;
    color: #4E4E4E;
    line-height: 40px;
}
.score-value {
    width: 107px;
    margin-top: 10px;
    font-size: 40px;
    font-family: Source Han Sans CN-Regular;
    font-weight: bold;
    color: #23B35E;
    line-height: 40px;
}
</style>
