<template>
    <div class="query-form">
        <el-form :model="form" ref="form" label-width="130px" class="demo-form">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="查询省份:" prop="fromProvince">
                        <div style="display: flex">
                            <el-select
                                style="margin-right: 20px"
                                v-model="form.fromProvince"
                                placeholder="请选择省份"
                                @change="
                                    handleSelectChange('fromProvince', $event)
                                "
                            >
                                <el-option
                                    v-for="option in fromProvinceOptions"
                                    :key="option.value"
                                    :label="option.label"
                                    :value="option.value"
                                ></el-option>
                            </el-select>
                            <el-select
                                v-show="!hideFromCity"
                                v-model="form.fromCity"
                                placeholder="请选择城市"
                                @change="handleSelectChange('fromCity', $event)"
                            >
                                <el-option
                                    v-for="option in fromCityOptions"
                                    :key="option.value"
                                    :label="option.label"
                                    :value="option.value"
                                ></el-option>
                            </el-select>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item
                        label="企业注册属地:"
                        prop="provinceCityCounty"
                    >
                        <div style="display: flex">
                            <el-select
                                style="margin-right: 20px"
                                v-model="form.province"
                                placeholder="请选择省级"
                                @change="handleSelectChange('province', $event)"
                            >
                                <el-option
                                    v-for="option in provinceOptions"
                                    :key="option.value"
                                    :label="option.label"
                                    :value="option.value"
                                ></el-option>
                            </el-select>
                            <el-select
                                style="margin-right: 20px"
                                v-model="form.city"
                                placeholder="请选择市级"
                                @change="handleSelectChange('city', $event)"
                            >
                                <el-option
                                    v-for="option in cityOptions"
                                    :key="option.value"
                                    :label="option.label"
                                    :value="option.value"
                                ></el-option>
                            </el-select>
                            <el-select
                                v-model="form.county"
                                placeholder="请选择县级"
                            >
                                <el-option
                                    v-for="option in countyOptions"
                                    :key="option.value"
                                    :label="option.label"
                                    :value="option.value"
                                ></el-option>
                            </el-select>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="18">
                    <el-form-item
                        :label="isFromFujian ? '类型:' : '评分单位类型:'"
                        prop="scoreName"
                    >
                        <div style="display: flex">
                            <span
                                @click="handType(index)"
                                style="
                                    white-space: nowrap;
                                    margin-right: 20px;
                                    padding: 0 10px;
                                    cursor: pointer;
                                "
                                :class="index == activeTypeIdx && 'is_active'"
                                v-for="(item, index) in typeList"
                                :key="index"
                                >{{ item }}</span
                            >

                            <el-button
                                v-show="fromCity"
                                :disabled="actDisabled"
                                style="margin-right: 20px"
                                type="primary"
                                @click="handleQuery"
                                >立即查询</el-button
                            >
                            <el-button v-show="fromCity" @click="handleReset()"
                                >重置</el-button
                            >
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row v-if="isFromFujian">
                <el-col :span="24">
                    <el-form-item label="排名/分数：" prop="startEndScoreRank">
                        <div
                            style="
                                display: flex;
                                height: 40px;
                                align-items: center;
                            "
                        >
                            <el-radio-group
                                v-model="form.startEndScoreRank"
                                @change="onChangeStartEndScoreRank"
                            >
                                <el-radio label="rank">排名(名)</el-radio>
                                <el-radio label="score">分数(分)</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col v-if="startEndScoreRank == 'rank'" :span="24">
                    <el-form-item label="" prop="startEndRank">
                        <div style="display: flex">
                            <el-input-number
                                :min="1"
                                style="width: 200px; margin-right: 20px"
                                placeholder="请输入起始排名"
                                v-model="form.startRank"
                            ></el-input-number>
                            <span>至</span>
                            <el-input-number
                                :min="1"
                                style="width: 200px; margin: 0 20px"
                                placeholder="请输入结束排名"
                                v-model="form.endRank"
                            ></el-input-number>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row
                v-if="!isFromFujian || startEndScoreRank == 'score'"
                v-show="!fromCity"
            >
                <el-col :span="24">
                    <el-form-item
                        :label="isFromFujian ? '' : '分数:'"
                        prop="startEndScore"
                    >
                        <div style="display: flex">
                            <el-input-number
                                :min="0"
                                :max="999"
                                style="width: 200px; margin-right: 20px"
                                placeholder="请输入最低分"
                                v-model="form.startScore"
                            ></el-input-number>
                            <span>至</span>
                            <el-input-number
                                :min="0"
                                :max="999"
                                style="width: 200px; margin: 0 20px"
                                placeholder="请输入最高分"
                                v-model="form.endScore"
                            ></el-input-number>

                            <span
                                @click="handGrade(index)"
                                style="
                                    white-space: nowrap;
                                    margin-right: 20px;
                                    padding: 0 10px;
                                    cursor: pointer;
                                "
                                :class="index == activeGradeIdx && 'is_active'"
                                v-for="(item, index) in gradeList"
                                :key="index"
                                >{{ item.label }}</span
                            >
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row v-show="!fromCity">
                <el-col :span="12">
                    <el-form-item label="评分期数:" prop="yearQuarter">
                        <div style="display: flex">
                            <el-select
                                style="margin-right: 20px"
                                v-model="form.year"
                                placeholder="请选择年份"
                                @change="handleSelectChange('year', $event)"
                            >
                                <el-option
                                    v-for="option in yearOptions"
                                    :key="option.value"
                                    :label="option.label"
                                    :value="option.value"
                                ></el-option>
                            </el-select>
                            <el-select
                                style="margin-right: 50px"
                                v-model="form.quarter"
                                placeholder="请选择期数"
                            >
                                <el-option
                                    v-for="option in quarterOptions"
                                    :key="option.value"
                                    :label="option.label"
                                    :value="option.value"
                                ></el-option>
                            </el-select>

                            <el-button
                                :disabled="actDisabled"
                                style="margin-right: 20px"
                                type="primary"
                                @click="handleQuery"
                                >立即查询</el-button
                            >
                            <el-button @click="handleReset()">重置</el-button>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import {
    getCommonColumnList,
    h5PartnerApplyRegion,
    getEnScoreQuarter,
} from "@/api/aptitude";

export default {
    props: {
        actDisabled: {
            type: Boolean,
            default: false,
        },
        initFromProvince: {
            type: String,
            default: "",
        }
    },
    components: {},
    data() {
        return {
            activeTypeIdx: -1,
            activeGradeIdx: -1,
            searchValue: "",
            form: {
                entName: "",
                scoreName: "",
                startEndScoreRank: "rank",
                fromProvince: "广西壮族自治区",
                fromCity: "",
                enterpriseType: "",
                province: "",
                city: "",
                county: "",
                year: "",
                quarter: "",
                startScore: undefined,
                endScore: undefined,
                startRank: undefined,
                endRank: undefined,
            },
            typeList: [],
            gradeList: [
                {
                    label: "90-100分",
                    value: [90, 100],
                },
                { label: "80-90分", value: [80, 90] },
                { label: "60-80分", value: [60, 80] },
                { label: "60分以下", value: [0, 60] },
            ],
            categoryOptions: [],
            provinceOptions: [],
            cityOptions: [],
            countyOptions: [],
            yearOptions: [],
            quarterOptions: [],
            regionList: [],
            quarterList: [],
            fromProvinceOptions: [
                {
                    label: "广西",
                    value: "广西壮族自治区",
                    child: [
                        {
                            label: "省内企业",
                            value: "广西壮族自治区",
                        },
                        {
                            label: "入桂企业",
                            value: "notProvince",
                        },
                    ],
                },
                {
                    label: "福建",
                    value: "福建省",
                    child: [
                        {
                            label: "省内企业",
                            value: "福建省",
                        },
                        {
                            label: "入闽企业",
                            value: "notProvince",
                        },
                    ],
                },
            ],
            fromCityOptions: [],
        };
    },
    computed: {
        isFromFujian() {
            return this.fromProvince == "福建省";
        },
        hideFromCity() {
            return this.fromProvince == "福建省";
        },
        startEndScoreRank: {
            get() {
                return this.form.startEndScoreRank;
            },
            set(val) {
                this.form.startEndScoreRank = val;
            },
        },
        entName: {
            get() {
                return this.form.entName;
            },
            set(val) {
                this.form.entName = val;
            },
        },
        scoreName: {
            get() {
                return this.form.scoreName;
            },
            set(val) {
                this.form.scoreName = val;
            },
        },
        fromProvince: {
            get() {
                return this.form.fromProvince;
            },
            set(val) {
                this.form.fromProvince = val;
            },
        },
        fromCity: {
            get() {
                return this.form.fromCity;
            },
            set(val) {
                this.form.fromCity = val;
            },
        },
        enterpriseType: {
            get() {
                return this.form.enterpriseType;
            },
            set(val) {
                this.form.enterpriseType = val;
            },
        },
        province: {
            get() {
                return this.form.province;
            },
            set(val) {
                this.form.province = val;
            },
        },
        city: {
            get() {
                return this.form.city;
            },
            set(val) {
                this.form.city = val;
            },
        },
        county: {
            get() {
                return this.form.county;
            },
            set(val) {
                this.form.county = val;
            },
        },
        year: {
            get() {
                return this.form.year;
            },
            set(val) {
                this.form.year = val;
            },
        },
        quarter: {
            get() {
                return this.form.quarter;
            },
            set(val) {
                this.form.quarter = val;
            },
        },
        startScore: {
            get() {
                return this.form.startScore;
            },
            set(val) {
                this.form.startScore = val;
            },
        },
        endScore: {
            get() {
                return this.form.endScore;
            },
            set(val) {
                this.form.endScore = val;
            },
        },
        startRank: {
            get() {
                return this.form.startRank;
            },
            set(val) {
                this.form.startRank = val;
            },
        },
        endRank: {
            get() {
                return this.form.endRank;
            },
            set(val) {
                this.form.endRank = val;
            },
        },
    },
    methods: {
        validate() {
            if (this.isFromFujian && 
                this.startEndScoreRank == 'rank' && this.startRank > this.endRank) {
                this.$message.error("起始排名不能大于结束排名");
                return false;
            }
            if (!this.fromCity && this.startScore > this.endScore) {
                this.$message.error("最低分不能大于最高分");
                return false;
            }
            return true;
        },
        onChangeStartEndScoreRank(value) {
            this.startRank = undefined;
            this.endRank = undefined;
            this.startScore = undefined;
            this.endScore = undefined;
        },
        onSelectChangeFromProvince(value) {
            this.fromProvince = value;
            console.log(
                "onSelectChangeFromProvince-->this.fromProvince=" +
                    this.fromProvince
            );
            this.fromCity = "";
            this.year = "";
            this.quarter = "";
            this.fromCityOptions = this.extractCityList();
            this.typeList = this.extractTypeList();
            this.handType(-1);
            this.loadScoreYearQuarterOptions();
        },
        onSelectChangeFromCity(value) {
            this.fromCity = value;
            console.log(
                "onSelectChangeFromCity-->this.fromCity=" + this.fromCity
            );
            this.typeList = this.extractTypeList();
            this.handType(-1);
        },
        onSelectChangeProvince(value) {
            this.province = value;
            this.city = "";
            this.county = "";
            this.loadCities(value);
        },
        onSelectChangeCity(value) {
            this.city = value;
            this.county = "";
            this.loadCounties(this.province, value);
        },
        onSelectChangeYear(value) {
            this.year = value;
            this.quarter = "";
            this.loadQuarters(value);
        },
        handleSelectChange(field, value) {
            switch (field) {
                case "fromProvince":
                    this.onSelectChangeFromProvince(value);
                    break;
                case "fromCity":
                    this.onSelectChangeFromCity(value);
                    break;
                case "province":
                    this.onSelectChangeProvince(value);
                    break;
                case "city":
                    this.onSelectChangeCity(value);
                    break;
                case "year":
                    this.onSelectChangeYear(value);
                    break;
                default:
                    break;
            }
        },
        extractProvinceOptions() {
            let options = [];
            (this.regionList || []).forEach((item) => {
                let opt = {
                    label: item.name,
                    value: item.adCode,
                };
                options.push(opt);
            });
            return options;
        },
        extractCityOptions(province) {
            let options = [];
            let target = (this.regionList || []).find(
                (item) => item.adCode == province
            );
            if (target) {
                (target.child || []).forEach((item) => {
                    let opt = {
                        label: item.name,
                        value: item.adCode,
                    };
                    options.push(opt);
                });
            }
            return options;
        },
        extractCountyOptions(province, city) {
            let options = [];
            let target = (this.regionList || []).find(
                (item) => item.adCode == province
            );
            if (target) {
                let targetCity = (target.child || []).find(
                    (item) => item.adCode == city
                );
                if (targetCity) {
                    (targetCity.child || []).forEach((item) => {
                        let opt = {
                            label: item.name,
                            value: item.adCode,
                        };
                        options.push(opt);
                    });
                }
            }
            return options;
        },
        extractQuarterOptions(year) {
            let options = [];
            let target = (this.quarterList || []).find(
                (item) => item.key == year
            );
            if (target) {
                (target.subResultList || []).forEach((item) => {
                    let opt = {
                        label: item.key,
                        value: item.key,
                    };
                    options.push(opt);
                });
            }
            return options;
        },
        async loadH5PartnerApplyRegion() {
            const response = await h5PartnerApplyRegion();

            let records = response.data ? response.data : [];
            this.regionList = records;
            this.provinceOptions = this.extractProvinceOptions();
        },
        async loadScoreYearQuarterOptions() {
            const response = await getEnScoreQuarter({
                fromProvince: this.fromProvince,
            });

            let records = response.data ? response.data : [];
            let years = [];

            (records || []).forEach((item) => {
                years.push({
                    label: item.key,
                    value: item.key,
                });
            });
            this.yearOptions = years;
            this.quarterList = records || [];
        },
        getCityIdBySymbol(symbol) {
            let target = (this.categoryOptions || []).find(
                (item) => item.type == "广西信用分类" && item.symbol == symbol
            );
            return target ? target.id : "";
        },
        extractCityList() {
            let list = [];
            if (this.fromProvince != "福建省") {
                (this.categoryOptions || []).forEach((item) => {
                    if (item.type == "广西信用分类") {
                        list.push({
                            label: item.symbol,
                            value: item.symbol,
                        });
                    }
                });
            }
            return list;
        },
        extractTypeList() {
            let list = [];
            let cityId = this.fromCity
                ? this.getCityIdBySymbol(this.fromCity)
                : "";

            (this.categoryOptions || []).forEach((item) => {
                if (this.fromProvince == "福建省") {
                    if (!this.fromCity) {
                        if (item.type == "350000") list.push(item.name);
                    }
                } else {
                    if (!this.fromCity) {
                        if (item.type == "450000") list.push(item.name);
                    } else {
                        if (item.pid == cityId) list.push(item.name);
                    }
                }
            });
            return list;
        },
        async loadCategoryOptions() {
            const response = await getCommonColumnList({
                appid: "wxbd94b37dd45c1f91",
                fromProvince: this.fromProvince,
            });

            let records = response.data ? response.data : [];

            this.categoryOptions = records;
            let typeList = this.extractTypeList();
            this.typeList = typeList;
            this.handType(-1);
            this.fromCityOptions = this.extractCityList();
        },
        loadCities(province) {
            console.log("loadCities-->province=" + province);
            this.cityOptions = this.extractCityOptions(province);
        },
        loadCounties(province, city) {
            console.log(
                "loadCounties-->province=" + province + ", city=" + city
            );
            this.countyOptions = this.extractCountyOptions(province, city);
        },
        loadQuarters(year) {
            console.log("loadQuarters-->year=" + year);
            this.quarterOptions = this.extractQuarterOptions(year);
        },
        getProvinceName(province) {
            let target = (this.regionList || []).find(
                (item) => item.adCode == province
            );
            return target ? target.name : "";
        },
        getCityName(province, city) {
            let targetCity = null,
                target = (this.regionList || []).find(
                    (item) => item.adCode == province
                );
            if (target) {
                targetCity = (target.child || []).find(
                    (item) => item.adCode == city
                );
            }
            return targetCity ? targetCity.name : "";
        },
        getCountyName(province, city, county) {
            let targetCounty = null,
                targetCity = null,
                target = (this.regionList || []).find(
                    (item) => item.adCode == province
                );
            if (target) {
                targetCity = (target.child || []).find(
                    (item) => item.adCode == city
                );
                if (targetCity) {
                    targetCounty = (targetCity.child || []).find(
                        (item) => item.adCode == county
                    );
                }
            }
            return targetCounty ? targetCounty.name : "";
        },
        getAllCategoryNames() {
            let categoryNames = [];
            (this.categoryOptions || []).forEach((item) => {
                if (item.value != "all") categoryNames.push(item.label);
            });
            return categoryNames;
        },
        handleReset() {
            if (this.$refs.form) this.$refs.form.resetFields();
            this.entName = "";
            this.scoreName = "";
            this.startEndScoreRank = "rank";
            this.fromProvince = "广西壮族自治区";
            this.fromCity = "";
            this.enterpriseType = "";
            this.province = "";
            this.city = "";
            this.county = "";
            this.year = "";
            this.quarter = "";
            this.startScore = undefined;
            this.endScore = undefined;
            this.startRank = undefined;
            this.endRank = undefined;
            this.handGrade(-1);

            this.resetOptions();
            this.$emit("reset");
        },
        handleQuery() {

            if (!this.validate())
                return;

            let formData = { ...this.form };

            if (formData.startEndScoreRank)
                delete formData.startEndScoreRank;
            if (this.province)
                formData.provinceName = this.getProvinceName(this.province);
            if (this.city)
                formData.cityName = this.getCityName(this.province, this.city);
            if (this.county)
                formData.countyName = this.getCountyName(this.province, this.city, this.county);
            
            if (this.fromCity) {
                formData.enterpriseType = this.scoreName;
                delete formData.scoreName;

                formData.startRank = "";
                formData.endRank = "";
                formData.startScore = "";
                formData.endScore = "";
                formData.year = "";
                formData.quarter = "";

            } else if (this.scoreName) {
                delete formData.enterpriseType;
            }

            console.log("handleQuery-->formData=", formData);
            this.$emit("submit", formData);
        },
        handType(index) {
            this.activeTypeIdx = (index >= 0) ? index : -1;
            this.scoreName = (index >= 0) ? this.typeList[index] : "";
        },
        handGrade(index) {
            this.activeGradeIdx = (index >= 0) ? index : -1;

            this.startScore = (index >= 0) ? this.gradeList[index].value[0] : undefined;
            this.endScore = (index >= 0) ? this.gradeList[index].value[1] : undefined;
        },
        resetOptions() {
            this.fromCityOptions = this.extractCityList();
            this.typeList = this.extractTypeList();
            this.handType(-1);
            this.fromCityOptions = this.extractCityList();
            this.loadScoreYearQuarterOptions();
        },
    },
    mounted() {
        if (this.initFromProvince)
            this.fromProvince = this.initFromProvince;
        this.loadCategoryOptions();
        this.loadH5PartnerApplyRegion();
        this.loadScoreYearQuarterOptions();
        this.handGrade(-1);
    },
};
</script>
<style scoped>
.list-z {
    text-align: left;
    font-weight: 500;
    color: #4e4e4e;
    line-height: 40px;
    margin-top: 35px;
    margin-bottom: 25px;
}
.fs-18 {
    font-size: 18px;
}
.mb-50 {
    margin-bottom: 50px;
}
div .el-form-item__label {
    text-align-last: justify !important;
    text-align: justify !important;
}
.is_active {
    color: #1890ff;
    background-color: #edf4ff;
    border-radius: 5px;
}
</style>
