<template>
  <div v-loading="loadingStatus">
    <el-table
      border
      :data="tableData"
      :header-cell-style="headerCellStyle"
      :default-sort="{ prop: sortProp, order: sortOrder }"
      @sort-change="onSortChange"
    >
      <el-table-column
        v-if="hasSnCol"
        type="index"
        label="序号"
        width="80"
        align="center"
        label-class-name="table-col-header-label"
      >
        <template slot-scope="{ $index }">
          <span>{{ computeIndex($index, currentPage, pagingSize) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="column in columns"
        header-align="center"
        align="center"
        :key="column.prop"
        :label="column.label"
        :prop="column.prop"
        :width="column.width"
        :min-width="column.minWidth"
        :sortable="column.sortable"
        label-class-name="table-col-header-label"
      >
        <template slot-scope="scope">
          <el-link
            v-if="column.hasLink"
            class="link-in-row"
            :underline="false"
            @click="doLinkAction(column.prop, scope.row)"
            >{{ scope.row[column.prop] }}</el-link
          >
          <span v-else-if="column.staticData">{{
            setStaticData(scope.row, column.staticData)
          }}</span>
          <span v-else>{{ scope.row[column.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="!hidePagination" style="text-align: right; margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pagingSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "GenericTable",
  props: {
    hasSnCol: {
      type: Boolean,
      default: true,
    },
    getData: {
      type: Function,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    sortProp: {
      type: String,
      default: "",
    },
    sortOrder: {
      type: String,
      default: "ascending",
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    headerCellBkcolor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loadingStatus: false,
      tableData: [],
      total: 0,
      currentPage: 1,
    };
  },
  computed: {
    headerCellStyle() {
      return this.headerCellBkcolor
        ? { background: this.headerCellBkcolor }
        : {};
    },
    isLoadingStatus() {
      return this.loadingStatus;
    },
    pagingSize() {
      return this.pageSize;
    },
  },
  methods: {
    setStaticData(row, prop) {
      let { value, param } = prop;
      param.forEach((item) => {
        value = value.replace("?", row[item]);
      });
      return value;
    },
    computeIndex(index, page, pageSize) {
      return index + 1 + (page - 1) * pageSize;
    },
    async fetchData(pagingSize) {
      if (this.loadingStatus) return;
      this.loadingStatus = true;
      try {
        const response = await this.getData({
          size: pagingSize || this.pagingSize,
          current: this.currentPage,
          // sortProp: this.sortProp, 当前API不支持
          // sortOrder: this.sortOrder 当前API不支持
        });
        this.tableData = response.data;
        this.total = response.total;
      } catch (res) {
        this.tableData = [];
        this.total = 0;
      } finally {
        this.loadingStatus = false;
      }
    },
    reload(currentPage) {
      console.log("reload..." + currentPage);
      this.handleCurrentChange(currentPage);
    },
    doLinkAction(prop, row) {
      this.$emit("linkAction", prop, row);
    },
    handleSizeChange(val) {
      // this.pageSize = val;
      this.fetchData(val);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchData();
    },
    onSortChange({ prop, order }) {
      this.sortProp = prop;
      this.sortOrder = order;
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
::v-deep .table-col-header-label {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular;
  font-weight: 500;
  color: #4e4e4e;
}
.link-in-row.el-link.el-link--default {
  font-size: 16px;
}
.link-in-row.el-link.el-link--default:hover {
  color: #007ef2;
}
::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  font-size: 16px !important;
}

</style>
