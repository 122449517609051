import request from '@src/plugins/request'
/**
 * @description 资质查询模块
 *
 * **/

// 企业备案省份列表查询
export const getEnterpriseRecordExistProvince = function (data) {
    return request({
        url: `/enterprise/record/exist/province`,
        method: 'get',
        data
      })
    }

// 企业信息高级查询
export const getDcEnterpriseHighPage = function (data) {
    return request({
        url: `/dcEnterprise/high/page2`,
        method: 'POST',
        data
      })
}

//企业查询详情
export const getDcEnterpriseDetail = function (data) {
    return request({
        url: '/dcEnterprise/detail',
        method: 'POST',
        data
    })
}

//企业资质分页列表查询
export const getEnQualificationPage = function (data) {
    return request({
        url: `/enQualification/page`,
        method: 'POST',
        data
      })
    }

//获取企业资质类别
export const getEnQualificationTypes = function (data) {
    return request({
        url: '/enQualification/getType',
        method: 'get',
        params: data
    })
}

// 获取企业资质类别1
export const getCommonQualificationTypes = function (data) {
    return request({
        url: "/common/qualification/type/cate1/list",
        method: 'get',
        params: data
    })
}

// 获取子集分类列表
export const getCommonQualificationTypes2 = function (data) {
    return request({
        url: "/common/qualification/type/cate2/list",
        method: 'POST',
        data
    })
}

// 获取三级分类列表
export const getCommonQualificationTypes3 = function (data) {
    return request({
        url: "/common/qualification/type/cate3/list",
        method: 'POST',
        data
    })
}

// 获取分页专业列表
//
// 	{"cate1List":["勘察企业"],"cate2List":["工程勘察综合资质"],"levelList":[],"cate3List":[],"current":1,"size":1000}
//
export const getCommonQualificationTypeProPage = function (data) {
    return request({
        url: "/common/qualification/type/pro/page",
        method: 'POST',
        data
    })
}

//查询浏览量
export const dcEnterpriseHits = function (data) {
    return request({
        url: '/api/dcEnterprise/hits',
        method: 'get',
        params: data
    })
}

//企业注册属地h5(获取加盟区域)
export const h5PartnerApplyRegion = function (data) {
    return request({
        url: '/h5/partnerApply/region',
        method: 'get',
        params: data
    })
}

//企业注册属地(获取加盟区域)
export const partnerApplyRegion = function (data) {
    return request({
        url: '/partnerApply/region',
        method: 'get',
        params: data
    })
}

//中标企业列表(今日中标企业/中标项目经理)
export const getTenderInvitationPage = function (data) {
    return request({
        url: '/tender/invitation/page',
        method: 'POST',
        data
    })
}

//中标企业列表(今日中标企业/中标项目经理)
export const getTenderInvitationPageUnLogin = function (data) {
    return request({
        url: '/unLogin/tender/invitation/page',
        method: 'POST',
        data
    })
}

// 企业人员分页列表查询(人员详情例子{"code":"渝1502020202101160","current":1,"size":20})
export const getEnterprisePersonPage = function (data) {
    return request({
        url: `enterprise/person/page`,
        method: 'POST',
        data
      })
}

// 获取人员注册分类列表
export const getEnterprisePersonTypes = function (data) {
    return request({
        url: `/enterprise/person/type/name`,
        method: 'get',
        params: data
      })
}

// 获取人员分类
export const getPersonTypesByCommonCategory = function (data) {
    return request({
        url: `/commonCategory/getAppidIsTree`,
        method: 'get',
        params: data
      })
}

// 企业工程项目分页列表注解查询(搜索工程项目)
export const getEnterpriseProjectNewPage = function (data) {
    return request({
        url: `/enterprise/project/new/page`,
        method: 'POST',
        data
      })
}

// 企业工程项目分页列表查询
export const getEnterpriseProjectInfoPage = function (data) {
    return request({
        url: `/enterprise/project/info/page`,
        method: 'POST',
        data
    })
}

// 查询项目类型列表
export const getEnterpriseProjectTypes = function (data) {
    return request({
        url: `/enterprise/project/prjtype/list`,
        method: 'get',
        params: data
      })
}

// 查询项目省份列表
export const getEnterpriseProjectProvinces = function (data) {
    return request({
        url: `/enterprise/project/province/list`,
        method: 'get',
        params: data
      })
}

// 企业电子证照查询列表
export const getEnEcLicencePage = function (data) {
    return request({
        url: `en/eclicence/page`,
        method: 'POST',
        data
      })
}

// 企业备案省份资质列表查询(企业分类)
export const getEntRecordFromProvinceQualification = function (data) {
    return request({
        url: "/enterprise/record/from/province/qualification",
        method: 'get',
        params: data
    })
}

//企业备案分页列表查询
export const getEntRecordPage = function (data) {
    return request({
        url: "/enterprise/record/page",
        method: 'POST',
        data
    })
}

//企业备案企业详情
export const getEntRecordDetail = function (data) {
    return request({
        url: '/enterprise/record/detail',
        method: 'POST',
        data
    })
}

//新数据结构-企业分值数据查询列表
export const getEntScoreNewPage = function (data) {
    return request({
        url: "/en/score/new/page",
        method: 'POST',
        data
    })
}

//评分单位类型
export const getCommonColumnList = function (data) {
    return request({
        url: `/common/column/list`,
        method: 'GET',
        params: data
    })
}

//评分期数
export const getEnScoreQuarter = function (data) {
    return request({
        url: `/en/score/quarter`,
        method: 'GET',
        params: data
    })
}
