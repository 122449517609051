<template>
    <div>
        <el-descriptions
            v-for="(card, cardIndex) in cards"
            :key="cardIndex"
            class="margin-top"
            labelClassName="detail-card-label"
            contentClassName="detail-card-value"
            :column="card.colsNum"
            :border="cardBordered"
        >
            <template slot="title" v-if="card.title">
                <span class="detail-card-title">
                    {{ card.title }}
                </span>
            </template>
            <template slot="extra" v-if="actions">
                <el-button
                    v-for="(act, idx) in actions"
                    :key="'act' + idx"
                    size="small"
                    type="info"
                    @click="doAction(act.value)"
                >
                    {{ act.label }}
                </el-button>
            </template>
            <el-descriptions-item
              :labelStyle="propsConfig.labelStyle"
              :contentStyle="propsConfig.contentStyle"
                v-for="(item, index) in card.fields"
                :key="index"
            >
                <template slot="label">
                    {{ item.label }}
                </template>
                <el-link v-if="item.hasLink" class="link-in-cell" :underline="false" @click="doLinkAction(item.label, item.value)">{{ item.value }}</el-link>
                <span v-else>{{ item.value }}</span>
            </el-descriptions-item>
        </el-descriptions>
    </div>
</template>
<script>
export default {
    props: {
        // 明细卡片相关属性
        cardBordered: {
            type: Boolean,
            default: true,
        },
        // 明细数据相关属性
        cards: {
            type: Array,
            default: () => [],
        },
        actions: {
            type: Array,
            default: () => [],
        },
      propsConfig: {
        type: Object,
        default: () => ({
          labelStyle: "width: 180px;",
          contentStyle: ""
        })
      }
    },
    methods: {
        doAction(actValue) {
            this.$emit("action", actValue);
        },
        doLinkAction(label, value) {
          this.$emit('linkAction', label, value);
        },
    },
};
</script>

<style scoped>
.detail-card-title {
    width: 456px;
    height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN-Regular;
    font-weight: bold;
    color: #1890ff;
    line-height: 40px;
}

.detail-card-divider {
    height: 1px;
    background: #e8e8e8;
    margin-bottom: 10px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
}

.detail-card-label {
    width: 232px;
    font-weight: bold;
    padding-top: 16px;
    padding-bottom: 16px;
}

.detail-card-value {
    white-space: pre-wrap;
}

.detail-card {
    overflow: hidden;
}
.detail-row {
    border: 1px solid #dcdcdc;
    border-bottom: none;
    display: table;
    width: 100%;
}

.detail-row:last-child {
    border-bottom: 1px solid #dcdcdc;
}

::v-deep .el-button--small {
    padding: 9px 15px;
    font-size: 18px;
    border-radius: 3px;
    font-family: Source Han Sans CN-Regular;
    font-weight: bold;
    color: #1890ff;
    background: #edf4ff;
    border-color: transparent;
}

.link-in-cell.el-link.el-link--default {
    color: #1890FF;
    font-size: 20px;
}
</style>
