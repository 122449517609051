<template>
  <div class="mb-50">
    <div v-show="!showDetail">
      <div class="list-z">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
          <el-breadcrumb-item :to="{ path: '/enterprise/home' }" style="color: #FFFFFF;">首页</el-breadcrumb-item>
          <el-breadcrumb-item>信用分</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="border-b">
        <query
          :actDisabled="isLoadingStatus()"
          :initFromProvince="fromProvince"
          @submit="handleQuery"
          @reset="handleReset"
        ></query>
      </div>
      <generic-table
        ref="genericTable"
        :hasSnCol="!isFromFujian"
        :get-data="getPageData"
        :columns="displayColumns"
        :page-size="size"
        :hide-pagination="false"
        :headerCellBkcolor="'#F8F8F8'"
        @linkAction="onLinkAction"
      ></generic-table>
    </div>
    <CreditScoreDetail
      v-if="showDetail"
      :row="showingRow"
      :previousRoute="myRoute"
      @close="onDetailClose"
    ></CreditScoreDetail>
  </div>
</template>

<script>
import GenericTable from "@/components/generic-table";
import CreditScoreDetail from "./detail";
import { getEntScoreNewPage } from "@/api/aptitude";
import Query from "./query.vue";
export default {
  components: {
    GenericTable,
    Query,
    CreditScoreDetail,
  },
  data() {
    return {
      showDetail: false,
      showingRow: {},
      size: 20,
      current: 1,
      searchValue: "",
      isSearchingName: false,
      creditCode: "",
      myRoute: {
        label: "信用分",
        value: "/creditscore/index",
      },
      formData: {
        fromProvince: "广西壮族自治区",
      },
      rankColumn: { label: "排名", prop: "rank", minWidth: 10 },
      displayColumns: [
        { label: "企业名称", prop: "subjectName", minWidth: 20, hasLink: true },
        { label: "评分单位类型", prop: "subjectSpeciality", minWidth: 20 },
        { label: "统一社会信用代码", prop: "creditCode", minWidth: 20 },
        { label: "企业所在地", prop: "provinceCityCounty", minWidth: 20 },
        { label: "得分", prop: "score", minWidth: 10 },
      ],
    };
  },
  computed: {
    isFromFujian() {
      return this.fromProvince == "福建省";
    },
    fromProvince() {
      return this.formData.fromProvince;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.extractFromProvinceCreditCode();
        let newVal = to.query ? to.query.name : "";
        if (!this.creditCode || newVal) {
          this.searchValue = newVal;
          console.log("this.searchValue=" + this.searchValue);
          this.onSearchValueChange();
        }
      },
    },
  },
  mounted(){
    document.title = "信用分_邦建通";
  },
  methods: {
    isLoadingStatus() {
      return (
        this.isSearchingName ||
        (this.$refs.genericTable && this.$refs.genericTable.isLoadingStatus)
      );
    },
    extractFromProvinceCreditCode() {
      const creditCode = this.$route.params.creditCode;
      const fromProvince = this.$route.params.fromProvince;
      this.creditCode = creditCode;
      if (fromProvince) {
        this.formData.fromProvince =
          fromProvince == "福建省" ? fromProvince : "广西壮族自治区";
        this.$route.params.creditCode = "";
        this.$route.params.fromProvince = "";
      } else {
        this.formData.fromProvince = "广西壮族自治区";
      }
      if (this.isFromFujian) {
        this.addRankColumn();
      } else {
        this.delRankColumn();
      }
    },
    addRankColumn() {
      let target = this.displayColumns.find((item) => item.prop == "rank");
      if (!target) {
        this.displayColumns.unshift(this.rankColumn);
      }
    },
    delRankColumn() {
      let target = this.displayColumns.findIndex((item) => item.prop == "rank");
      if (target >= 0) {
        this.displayColumns.splice(target, 1);
      }
    },
    handleQuery(formData) {
      console.log("handleQuery-->formData", formData);
      this.formData = formData;

      if (this.isFromFujian) {
        this.addRankColumn();
      } else {
        this.delRankColumn();
      }
      this.reloadTablePage(1);
    },
    handleReset() {
      console.log("handleReset...");
    },
    onSearchValueChange() {
      this.isSearchingName = true;
      this.reloadTablePage(1);
    },
    reloadTablePage(current) {
      this.current = current;
      if (this.$refs.genericTable) this.$refs.genericTable.reload(this.current);
    },
    onLinkAction(prop, row) {
      if (prop == "entName") {
        console.log("linkAction", prop, row);
        this.showingRow = row;
        this.showDetail = true;
      }
    },
    onDetailClose() {
      this.showingRow = {};
      this.showDetail = false;
    },
    async getPageData({ size, current }) {
      this.size = size;
      this.current = current;

      let params = {
        size,
        current,
      };
      if (!this.isSearchingName) {
        Object.assign(params, this.formData);
        if (this.creditCode) {
          params.creditCode = this.creditCode;
          this.creditCode = "";
        }
      } else {
        params.fromProvince = this.formData.fromProvince;
        params.entName = this.searchValue;
      }
      console.log("getPageData-->params=", params);
      let records = [];
      let total = 0;
      try {
        // 获取数据的 API 请求
        const response = await getEntScoreNewPage(params);

        records = response.data ? response.data.records : [];
        total = response.data ? response.data.total : 0;
        (records || []).forEach((item) => {
          item.provinceCityCounty =
            item.provinceName +
            (item.cityName ? "-" + item.cityName : "") +
            (item.countyName ? "-" + item.countyName : "");
        });
      } catch (res) {
      } finally {
        this.isSearchingName = false;
      }
      return {
        data: records,
        total: total,
      };
    },
  },
};
</script>
<style scoped>
.table-header-area {
  display: flex;
}
.list-z {
  text-align: left;
  font-weight: 500;
  color: #4e4e4e;
  line-height: 40px;
  margin-top: 35px;
  margin-bottom: 25px;
}
.fs-18 {
  font-size: 18px;
}
.mb-50 {
  margin-bottom: 50px;
}
.table-header {
  width: 520px;
  height: 60px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-left: 20px;
}
.border-b {
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #dcdcdc;
  text-align: left;
  padding-top: 32px;
  padding-left: 20px;
  padding-bottom: 16px;
}

.table-title {
  width: 233px;
  height: 24px;
  font-size: 24px;
  font-family: Source Han Sans CN-Regular;
  font-weight: 500;
  line-height: 60px;
}

.right-header {
  text-align: right;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-right: 20px;
  flex: 1;
}
.view-more {
  font-size: 20px;
  font-family: Source Han Sans CN-Regular;
  font-weight: 500;
  color: #888888;
  line-height: 60px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}

.view-more::after {
  content: "";
  background-image: url(~@/assets/images/more11941.png);
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}
::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  font-size: 18px;
}
::v-deep .el-link {
  font-size: 18px;
}
::v-deep .el-button--primary {
  background-color: #007ef2;
  border-color: #007ef2;
}
</style>
  